import React from "react";

import { BasicEttan } from "../../particles/particles";

const Ettan = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="800"
        height="370"
        viewBox="-45 -5 305 320"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>trigonometriska ettan</title>
        <desc>Enhetscirkeln och radian</desc>
        <BasicEttan />
      </svg>
    </>
  );
};

export default Ettan;
