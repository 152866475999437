import React from "react";

import { LanguageSelect } from "../particles/LanguageSelect";

const information = [
  {
    language: "in English",
    title: "My page",
    maintext: (
      <>
        <p className="migText__p">
          On this website you can find a description of my research, list to my
          books and links to them, books written in collaboration with my
          husband{" "}
          <a
            className="migText__a"
            href="https://www.studentlitteratur.se/person/forfattare/wiggo-kilborn/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wiggo Kilborn
          </a>{" "}
          and also link to my publications in English, Swedish and in my native
          language Russian. I hope that you are interested in the content of my
          scientific and didactical work with a focus on how mathematical
          concepts, structure, models and methods can be applied in mathematics
          education, mechanics and economics.
        </p>
      </>
    ),
  },
  {
    language: "på Svenska",
    title: "Min webbsida",
    maintext: (
      <>
        <p className="migText__p">
          På den här webbsidan kan du finna en beskrivning min forskning, böcker
          som är skrivna i samarbete med min make{" "}
          <a
            className="migText__a"
            href="https://www.studentlitteratur.se/person/forfattare/wiggo-kilborn/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wiggo Kilborn
          </a>{" "}
          samt länk till mina publikationer, publicerade på engelska, svenska
          och på mitt modersmål ryska. Jag hoppas att du är intresserad av min
          forsknings och mina didaktiska arbeten med fokus på hur matematiska
          begrepp, strukturer, modeller och metoder kan tillämpas inom
          forskningsområden såsom matematikdidaktik, mekanik och ekonomi.{" "}
        </p>
      </>
    ),
  },
];

class TextInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btnSelected: 0,
    };
  }

  onSelect = (i) => {
    this.setState({
      btnSelected: i,
      oldSelected: null,
    });
  };

  render() {
    const Index = this.state.btnSelected;
    const Title = information[Index].title;
    const Txt = information[Index].maintext;

    return (
      <>
        <div className="blist" key={Index}>
          <LanguageSelect
            information={information}
            onSelect={this.onSelect}
            Index={Index}
            key={Index}
          />
        </div>
        <article className="migText">
          <div>
            <h3 className="migText__h3"> {Title} </h3>
          </div>
          <div className="txt">{Txt}</div>
        </article>
      </>
    );
  }
}
export default TextInner;
