import React from "react";

import { SinCos45 } from "../../particles/particles";

const SinCosFortyFive = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="800"
        height="370"
        viewBox="-45 -5 305 320"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>Sin och Cos 45 degree</title>
        <desc>Sin och Cos 45 degree</desc>
        <SinCos45 />
      </svg>
    </>
  );
};

export default SinCosFortyFive;
