import React from "react";

const Main = ({ title, graphic }) => {
  return (
    <main className="ettan_main">
      <div className="ettan_graph">
        <section className="ettan_graph__flex">
          <h2 className="ettan_h2">{title}</h2>
          <div className="ettan_graph__graphic">{graphic}</div>
        </section>
      </div>
    </main>
  );
};

export default Main;
