import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/ettan/logo/logo.svg";
import { ReactComponent as MobileLogo } from "../../../assets/ettan/logo/logo_mobile.svg";
import { ReactComponent as Home } from "../../../assets/main/house.svg";

const Header = ({ toHomepage }) => {
  return (
    <header className="ettan_header">
      <section
        className="ettan_logo"
        onClick={() => {
          toHomepage();
        }}
      >
        <div className="rectangle" />
        <Logo />
      </section>
      <section className="home_container">
        <Link to="/">
          <Home />{" "}
        </Link>
      </section>
      <section
        className="ettan_mobile_logo"
        onClick={() => {
          toHomepage();
        }}
      >
        <MobileLogo />
      </section>

      <div className="back_container">
        <Link to="/">&#8678; back to nataliakarlsson.se</Link>
      </div>
    </header>
  );
};

export default Header;
