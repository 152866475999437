import React from "react";

import { Vinkel360 } from "../../particles/particles";

const Vinkel = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="800"
        height="370"
        viewBox="-45 -5 305 320"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>vinkel 360grd</title>
        <desc>vinkel 360grd</desc>
        <Vinkel360 />
      </svg>
    </>
  );
};

export default Vinkel;
