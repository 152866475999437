import React from "react";
import Header from "./components/particles/Header";
import Main from "./components/FiguresMain";
import Footer from "./components/particles/Footer";
import Filter from "./components/particles/Filter";
import Doodle from "./components/particles/Doodle";

import { array } from "./components/array/Array";

class FiguresModule extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  render() {
    const figures = array;
    return (
      <div className="figures_root">
        <Header />
        <Main figures={figures} click={this.increment} />
        <Footer />
        <Filter />
        <Doodle />
      </div>
    );
  }
}

export default FiguresModule;
