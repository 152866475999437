import React, { Fragment } from "react";
import Media from "react-media";

import { ReactComponent as Sdh } from "../../../../assets/main/sdh.svg";
import { ReactComponent as Gmail } from "../../../../assets/main/social/gmail.svg";
import { ReactComponent as Facebook } from "../../../../assets/main/social/facebook.svg";
import { ReactComponent as Linked } from "../../../../assets/main/social/linked.svg";
import { ReactComponent as GmailM } from "../../../../assets/main/social/gmail_m.svg";
import { ReactComponent as FacebookM } from "../../../../assets/main/social/facebook_m.svg";
import { ReactComponent as LinkedM } from "../../../../assets/main/social/linked_m.svg";

class Kontakt extends React.Component {
  render() {
    return (
      <div className="kontlist">
        <div className="kontcenter">
          <div className="ktop">
            <h1 className="primary_h1">Contact information</h1>
            <h2 className="primary_h2">Kontaktinformation</h2>
          </div>

          <div className="kinfo">
            <div className="kmain">
              <div>
                <p>
                  Mail:{" "}
                  <a
                    href="mailto:natalia.karlsson@sh.se"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    natalia.karlsson@sh.se
                  </a>
                </p>
              </div>
              <div>
                <p>
                  Facebook:{" "}
                  <a
                    href="https://sv-se.facebook.com/natalia.karlsson.7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://sv-se.facebook.com/natalia.karlsson.7
                  </a>
                </p>
              </div>
              <div>
                <p>
                  LinkedIn:{" "}
                  <a
                    href="http://linkedin.com/in/natalia-karlsson-55911568"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://linkedin.com/in/natalia-karlsson-55911568
                  </a>
                </p>
              </div>
            </div>
            <div className="linkto">
              <a
                href="https://www.sh.se/english/sodertorn-university/contact/researchers/natalia-karlsson"
                target="_blank"
                rel="noopener noreferrer"
                alt="Södertörns högskola"
              >
                <Sdh />
              </a>
            </div>

            <Media
              queries={{
                small: "(max-width: 750px)",
                medium: "(min-width: 751px) and (max-width: 1281px)",
                large: "(min-width: 1282px)",
              }}
            >
              {(matches) => (
                <Fragment>
                  {matches.small && (
                    <div>
                      {" "}
                      <div className="kbot">
                        <a
                          href="mailto:natalia.karlsson@sh.se"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <GmailM />
                        </a>
                        <a
                          href="https://sv-se.facebook.com/natalia.karlsson.7"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FacebookM />
                        </a>
                        <a
                          href="http://linkedin.com/in/natalia-karlsson-55911568"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedM />
                        </a>
                      </div>
                    </div>
                  )}
                  {matches.medium && (
                    <div>
                      {" "}
                      <div className="kbot">
                        <a
                          href="mailto:natalia.karlsson@sh.se"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Gmail />
                        </a>
                        <a
                          href="https://sv-se.facebook.com/natalia.karlsson.7"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Facebook />
                        </a>
                        <a
                          href="http://linkedin.com/in/natalia-karlsson-55911568"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Linked />
                        </a>
                      </div>
                    </div>
                  )}
                  {matches.large && (
                    <div>
                      {" "}
                      <div className="kbot">
                        <a
                          href="mailto:natalia.karlsson@sh.se"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Gmail />
                        </a>
                        <a
                          href="https://sv-se.facebook.com/natalia.karlsson.7"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Facebook />
                        </a>
                        <a
                          href="http://linkedin.com/in/natalia-karlsson-55911568"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Linked />
                        </a>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </Media>
            <footer className="year">
              <span>
                <a
                  className="mailtome"
                  href="mailto:dmitry.marklund@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Developer
                </a>{" "}
                &copy; 2024
              </span>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}
export default Kontakt;
