import React from "react";

import { SinCos090180270 } from "../../particles/particles";

const SinCosRange = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="800"
        height="370"
        viewBox="-45 -5 305 320"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>Sin och Cos 0, 90, 180, 270 degree</title>
        <desc>Sin och Cos 0, 90, 180, 270 degree</desc>
        <SinCos090180270 />
      </svg>
    </>
  );
};

export default SinCosRange;
