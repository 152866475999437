import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../../assets/figurer/logo/Logo.svg";
import { ReactComponent as LogoFigures } from "../../../../assets/figurer/logo/Logo_Figures.svg";
import { ReactComponent as LogoHome } from "../../../../assets/figurer/logo/Home.svg";
import { ReactComponent as Home } from "../../../../assets/main/house.svg";

const Header = () => {
  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <header className="header">
      <div className="logo_container" onClick={refreshPage}>
        <LogoFigures />
        <Logo />
      </div>
      <div className="home_container">
        <Link to="/">
          <Home />
        </Link>
      </div>
      <div className="back_container">
        <Link to="/">&#8678; back to nataliakarlsson.se</Link>
      </div>
    </header>
  );
};

export default Header;
