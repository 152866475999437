import React, { Component } from "react";

export class LanguageSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { information, Index } = this.props;

    return (
      <div>
        {information.map((l, i) => {
          return (
            <button
              key={i}
              className={Index === i ? "btnB" : "btnA"}
              onClick={() => this.props.onSelect(i)}
            >
              {l.language}
            </button>
          );
        })}
      </div>
    );
  }
}
