import Ettan from "../svgcomponents/ettan/Ettan";
import Vinkel from "../svgcomponents/ettan/Vinkel";
import SinCosRange from "../svgcomponents/ettan/SinCos090180270";
import SinCosAlpha from "../svgcomponents/ettan/SinCosA";
import SinCosThirty from "../svgcomponents/ettan/SinCos30";
import SinCosFortyFive from "../svgcomponents/ettan/SinCos45";
import SinCosSixty from "../svgcomponents/ettan/SinCos60";

export const ettanArray = [
  {
    menu: "trigonometriska ettan",
    title: `$trigonometriska$ $ettan$`,
    to: "ettan",
    description: "$Enhetscirkeln$ $och$ $radian$",
    svg: <Ettan />,
  },
  {
    menu: "vinkel 360 degree",
    title: "$vinkel$ $360^°$",
    to: "ettan/vinkel",
    description: "Vinkel $360^°$",
    svg: <Vinkel />,
  },
  {
    menu: "sin och cos 0, 90, 180, 270 och 360 degree",
    title:
      "{ $sin$ $0^°$, $90^°$, $180^°$, $270^°$, $360^°$ <br> $och$ $cos$ $0^°$, $90^°$, $180^°$, $270^°$, $360^°$ }",
    to: "ettan/adeg",
    description: "Exacta värden för $sin$ $och$ $cos$ ",
    svg: <SinCosRange />,
  },
  {
    menu: "sin och cos alpha",
    title: "$sin$ $α$ $och$ $cos$ $α$",
    to: "ettan/adeg",
    description: "Exacta värden för $sinα$ $och$ $cosα$",
    svg: <SinCosAlpha />,
  },
  {
    menu: "sin och cos 30 degree",
    title: "$sin$ $30^°$ $och$ $cos$ $30^°$",
    to: "ettan/30deg",
    description: "Exacta värden för $sin30^°$ $och$ $cos30^°$",
    svg: <SinCosThirty />,
  },
  {
    menu: "sin och cos 45 degree",
    title: "$sin$ $45^°$ $och$ $cos$ $45^°$",
    to: "ettan/45deg",
    description: "Exacta värden för $sin45^°$ $och$ $co45^°$",
    svg: <SinCosFortyFive />,
  },
  {
    menu: "sin och cos 60 degree",
    title: "$sin$ $60^°$ $och$ $cos$ $60^°$",
    to: "ettan/60deg",
    description: "Exacta värden för  $sin60^°$ $och$ $cos60^°$",
    svg: <SinCosSixty />,
  },
];
