import { ReactComponent as Parallellogram } from "../../../../assets/figurer/figures/Parallellogram.svg";
import { ReactComponent as Rektangel } from "../../../../assets/figurer/figures/Rektangel.svg";
import { ReactComponent as Kvadrat } from "../../../../assets/figurer/figures/Kvadrat.svg";
import { ReactComponent as Romb } from "../../../../assets/figurer/figures/Romb.svg";

import { ReactComponent as Triangel } from "../../../../assets/figurer/figures/Triangel.svg";
import { ReactComponent as LiksidigTriangel } from "../../../../assets/figurer/figures/LiksidigTriangel.svg";
import { ReactComponent as LikbentTriangel } from "../../../../assets/figurer/figures/LikbentTriangel.svg";
import { ReactComponent as KongruentTriangel } from "../../../../assets/figurer/figures/KongruentTriangel.svg";
import { ReactComponent as RatvinkligTriangel } from "../../../../assets/figurer/figures/RatvinkligTriangel.svg";
import { ReactComponent as TrubbigTriangel } from "../../../../assets/figurer/figures/TrubbigTriangel.svg";
import { ReactComponent as SpetsvinkligTriangel } from "../../../../assets/figurer/figures/SpetsvinkligTriangel.svg";

import { ReactComponent as LiksidigTriangelSymmetri } from "../../../../assets/figurer/figures/LiksidigTriangelSymmetri.svg";
import { ReactComponent as LikbentTriangelSymmetri } from "../../../../assets/figurer/figures/LikbentTriangelSymmetri.svg";

export const array = [
  {
    name: "Parallellogram",
    svg: <Parallellogram />,
  },
  {
    name: "Rektangel",
    svg: <Rektangel />,
  },
  {
    name: "Kvadrat",
    svg: <Kvadrat />,
  },
  {
    name: "Romb",
    svg: <Romb />,
  },
  {
    name: "Triangel",
    svg: <Triangel />,
  },
  {
    name: "Liksidig triangel",
    svg: <LiksidigTriangel />,
  },
  {
    name: "Liksidig triangel Symmetri",
    svg: <LiksidigTriangelSymmetri />,
  },
  {
    name: "Likbent triangel",
    svg: <LikbentTriangel />,
  },
  {
    name: "Likbent triangel Symmetri",
    svg: <LikbentTriangelSymmetri />,
  },
  {
    name: "Kongruent triangel",
    svg: <KongruentTriangel />,
  },
  {
    name: "Rätvinklig triangel",
    svg: <RatvinkligTriangel />,
  },
  {
    name: "Trubbig triangel",
    svg: <TrubbigTriangel />,
  },
  {
    name: "Spetsvinklig triangel",
    svg: <SpetsvinkligTriangel />,
  },
];
