import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ettanArray } from "./components/array/array";

import Header from "./components/Header";
import Aside from "./components/Aside";
import Main from "./components/Main";
import Footer from "./components/Footer";
import MobileNavigation from "./components/MobileNavigation";

const App = () => {
  const [funcs, setFuncs] = useState([]);
  const [title, setTitle] = useState("");
  const [graphic, setGraphic] = useState("");
  const [index, setIndex] = useState("");
  let navigate = useNavigate();

  const defaultGraphic = `På den här webbsidan kan du följa upp trigonometriska ettan. Lycka till i din strävan och vilja
  att lära och förstå matematiken.`;

  const mainToggle = (i) => {
    setIndex(i);
  };

  const subToggle = (x) => {
    setIndex("");
  };

  const headerToggle = () => {
    setIndex("");
  };

  const toHomepage = () => {
    headerToggle();
    setTitle("");
    setGraphic(defaultGraphic);
    navigate("/ettan", { replace: true });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (performance.getEntriesByType("reload")) {
      toHomepage();
    }
    setFuncs(ettanArray);
    setTitle("");
    setGraphic(defaultGraphic);
  }, []);

  return (
    <div className="ettan_root">
      <Header toHomepage={toHomepage} />
      <MobileNavigation
        funcs={funcs}
        setTitle={setTitle}
        setGraphic={setGraphic}
        defaultGraphic={defaultGraphic}
        index={index}
        mainToggle={mainToggle}
      />
      <Aside
        setTitle={setTitle}
        setGraphic={setGraphic}
        funcs={funcs}
        index={index}
        mainToggle={mainToggle}
        subToggle={subToggle}
      />
      <Main title={title} funcs={funcs} graphic={graphic} />
      <Footer />
    </div>
  );
};

export default App;
