import React, { useState } from "react";
import { Link } from "react-router-dom";
import uuid from "react-uuid";

import { ReactComponent as Cross } from "../../../assets/figurer/icons/Cross.svg";
import { ReactComponent as Burger } from "../../../assets/figurer/icons/Burger.svg";

const MobileNavigation = ({
  funcs,
  setTitle,
  setGraphic,
  defaultTitle,
  index,
  mainToggle,
}) => {
  const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false);

  const handleToggle = () => {
    setMobileNavigationOpen(!mobileNavigationOpen);
  };

  return (
    <>
      <nav className="ettan_mobile" role="navigation">
        <button className="ettan_mobile__button" onClick={handleToggle}>
          {mobileNavigationOpen ? <Cross /> : <Burger />}
        </button>
        <ul
          className={`ettan_mobile__menu${mobileNavigationOpen ? " show" : ""}`}
        >
          <TriMobileMenu
            funcs={funcs}
            setTitle={setTitle}
            setGraphic={setGraphic}
            defaultTitle={defaultTitle}
            index={index}
            mainToggle={mainToggle}
          />
          &nbsp;
          <li className="ettan_mobile__listItem">
            <Link to="/">&#8678; back to nataliakarlsson.se</Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export const TriMobileMenu = ({ funcs, setTitle, setGraphic, mainToggle }) => {
  return (
    <>
      {funcs.map((func, i) => {
        return (
          <li key={uuid()}>
            <Link
              to={func.to}
              key={i}
              className={`ettan_mobile__listItem`}
              onClick={() => {
                mainToggle(i);
                setTitle(func.menu);
                setGraphic(func.svg);
              }}
            >
              <p className="ettan_mobile__listItem__p">{func.menu}</p>{" "}
            </Link>
          </li>
        );
      })}
    </>
  );
};

export default MobileNavigation;
