import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <a
        className="footer__mail"
        href="mailto:dmitry.marklund@gmail.com"
        target="_blank noreferrer noopener"
      >
        Dmitry Marklund
      </a>{" "}
      <span className="footer__mail">🌸 2022</span>
    </footer>
  );
};

export default Footer;
