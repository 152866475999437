import React, { Fragment } from "react";
import Media from "react-media";

import Plx from "react-plx";

const pubData = [
  {
    start: ".pub-trigger",
    duration: "200vh",
    properties: [
      {
        startValue: 10,
        endValue: -200,
        unit: "vh",
        property: "translateY",
      },
    ],
  },
];

class Books extends React.Component {
  render() {
    return (
      <>
        <Media
          queries={{
            small: "(max-width: 750px)",
            medium: "(min-width: 751px) and (max-width: 1199px)",
            large: "(min-width: 1200px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.small && (
                <div className="public">
                  <div className="publiccenter">
                    <h1 className="primary_h1">Books</h1>
                    <h2 className="primary_h2">Böcker</h2>

                    <h3 className="primary_h3">Books for higher education</h3>
                    <h4 className="primary_h4">Böcker för högre utbildning</h4>

                    <div className="pubText">
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.bokus.com/bok/9789144134437/vad-ska-eleverna-lara-sig-och-vad-lar-de-sig-vanliga-missforstand-i-matematikundervisningen/"
                        >
                          Karlsson, N. & Kilborn, W. (2020).{" "}
                          <span>
                            Vad ska eleverna lära sig och vad lär de sig?
                            Vanliga missförstånd i matematikundervisningen.
                          </span>{" "}
                          Lund: Studentlitteratur.{" "}
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.diva-portal.org/smash/get/diva2:1266705/FULLTEXT01.pdf"
                        >
                          Karlsson, N. & Kilborn, W. (2018).{" "}
                          <span>
                            Det räcker om de förstår den. En studie av lärares
                            och elevers uppfattningar om multiplikation och
                            multiplikationstabellen.
                          </span>{" "}
                          Södertörn Studies in Higher Education.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://ncm.gu.se/wp-content/uploads/2018/03/skriftserie-nr2-karlsson_kilborn.pdf"
                        >
                          Karlsson, N. & Kilborn, W. (2017).{" "}
                          <span>
                            Formativ bedömning och didaktiskt stöd i matematik
                            för lärarstudenter: Diagnoser med didaktisk
                            uppföljning.
                          </span>{" "}
                          Göteborg: Göteborgs Universitet, NCM.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/kurslitteratur/lararutbildning-och-pedagogik/amnesdidaktik-f-6/konkretisering-och-undervisning-i-matematik"
                        >
                          Karlsson, N. & Kilborn, W. (2015).{" "}
                          <span>
                            Konkretisering och undervisning i matematik.
                            Matematikdidaktik för lärare.
                          </span>{" "}
                          Lund: Studentlitteratur.{" "}
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.gleerups.se/universitet-och-hogskola/universitet-och-hogskola-lararutbildning-pedagogik/lararutbildning-och-pedagogik-didaktik-och-pedagogik/matematikdidaktik-i-praktiken-att-undervisa-i-arskurs-1-6-p40688743"
                        >
                          Karlsson, N. & Kilborn, W. (2015).{" "}
                          <span>Matematikdidaktik i praktiken.</span> Malmö:
                          Gleerups Utbildning AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.gleerups.se/universitet-och-hogskola/universitet-och-hogskola-lararutbildning-pedagogik/lararutbildning-och-pedagogik-matematik/problemlosning-och-matematisk-modellering-p40692078"
                        >
                          Karlsson, N. & Kilborn, W. (2015).{" "}
                          <span>
                            Problemlösning och matematisk modellering.
                          </span>{" "}
                          Malmö: Gleerups Utbildning AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.bokus.com/bok/9789144090191/grundlaggande-algebra-funktioner-sannolikhetslara-och-statistik-matematikdidaktik-for-larare/"
                        >
                          Karlsson, N. & Kilborn, W. (2014).{" "}
                          <span>
                            Grundläggande algebra, funktioner, sannolikhetslära
                            och statistik. Matematikdidaktik för lärare.
                          </span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <h3 className="primary_h3">Books for gifted education</h3>
                      <h4 className="primary_h4">
                        Böcker för utbildning för begåvade elever
                      </h4>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a-lararpaket---tryckt-bok--digital-lararlicens-36-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>
                            Lärarhandledning. Matematisk problemlösning 1-3.
                          </span>{" "}
                          Lund: Studentlitteratur.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a1---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel A1. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a2-elevpaket---tryckt-bok---digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel A2. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a3-elevpaket---tryckt-bok--digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel A3. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b-lararpaket---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel B Lärarpaket.</span> Lund:
                          Studentlitteratur.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b1---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel B1. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b2---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel B2. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b3---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel B3. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c-lararpaket---tryckt-bok--digital-lararlicens-36-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>
                            Lärarhandledning. Matematisk problemlösning 7-9.
                          </span>{" "}
                          Lund: Studentlitteratur.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c1---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel C1. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c2-elevpaket---tryckt-bok---digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel C2. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c3-elevpaket---tryckt-bok---digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel C3. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <h3 className="primary_h3">Publications</h3>
                      <h4 className="primary_h4">Publikationer</h4>
                      <div className="pubDiva">
                        <a
                          className="Bbutton"
                          href="http://sh.diva-portal.org/smash/resultList.jsf?aq2=%5B%5B%5D%5D&af=%5B%5D&searchType=RESEARCH&sortOrder2=title_sort_asc&query=&language=sv&aq=%5B%5B%7B%22personId%22%3A%22*shknna09*%22%7D%5D%5D&sf=all&aqe=%5B%5D&sortOrder=dateIssued_sort_desc&onlyFullText=false&noOfRows=50&dswid=-9221"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link to DiVa
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {matches.medium && (
                <Plx className="public" parallaxData={pubData}>
                  <div className="publiccenter">
                    <h1 className="primary_h1">Books</h1>
                    <h2 className="primary_h2">Böcker</h2>

                    <h3 className="primary_h3">Books for higher education</h3>
                    <h4 className="primary_h4">Böcker för högre utbildning</h4>

                    <div className="pubText">
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.bokus.com/bok/9789144134437/vad-ska-eleverna-lara-sig-och-vad-lar-de-sig-vanliga-missforstand-i-matematikundervisningen/"
                        >
                          Karlsson, N. & Kilborn, W. (2020).{" "}
                          <span>
                            Vad ska eleverna lära sig och vad lär de sig?
                            Vanliga missförstånd i matematikundervisningen.
                          </span>{" "}
                          Lund: Studentlitteratur.{" "}
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.diva-portal.org/smash/get/diva2:1266705/FULLTEXT01.pdf"
                        >
                          Karlsson, N. & Kilborn, W. (2018).{" "}
                          <span>
                            Det räcker om de förstår den. En studie av lärares
                            och elevers uppfattningar om multiplikation och
                            multiplikationstabellen.
                          </span>{" "}
                          Södertörn Studies in Higher Education.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://ncm.gu.se/wp-content/uploads/2018/03/skriftserie-nr2-karlsson_kilborn.pdf"
                        >
                          Karlsson, N. & Kilborn, W. (2017).{" "}
                          <span>
                            Formativ bedömning och didaktiskt stöd i matematik
                            för lärarstudenter: Diagnoser med didaktisk
                            uppföljning.
                          </span>{" "}
                          Göteborg: Göteborgs Universitet, NCM.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/kurslitteratur/lararutbildning-och-pedagogik/amnesdidaktik-f-6/konkretisering-och-undervisning-i-matematik"
                        >
                          Karlsson, N. & Kilborn, W. (2015).{" "}
                          <span>
                            Konkretisering och undervisning i matematik.
                            Matematikdidaktik för lärare.
                          </span>{" "}
                          Lund: Studentlitteratur.{" "}
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.gleerups.se/universitet-och-hogskola/universitet-och-hogskola-lararutbildning-pedagogik/lararutbildning-och-pedagogik-didaktik-och-pedagogik/matematikdidaktik-i-praktiken-att-undervisa-i-arskurs-1-6-p40688743"
                        >
                          Karlsson, N. & Kilborn, W. (2015).{" "}
                          <span>Matematikdidaktik i praktiken.</span> Malmö:
                          Gleerups Utbildning AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.gleerups.se/universitet-och-hogskola/universitet-och-hogskola-lararutbildning-pedagogik/lararutbildning-och-pedagogik-matematik/problemlosning-och-matematisk-modellering-p40692078"
                        >
                          Karlsson, N. & Kilborn, W. (2015).{" "}
                          <span>
                            Problemlösning och matematisk modellering.
                          </span>{" "}
                          Malmö: Gleerups Utbildning AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.bokus.com/bok/9789144090191/grundlaggande-algebra-funktioner-sannolikhetslara-och-statistik-matematikdidaktik-for-larare/"
                        >
                          Karlsson, N. & Kilborn, W. (2014).{" "}
                          <span>
                            Grundläggande algebra, funktioner, sannolikhetslära
                            och statistik. Matematikdidaktik för lärare.
                          </span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <h3 className="primary_h3">Books for gifted education</h3>
                      <h4 className="primary_h4">
                        Böcker för utbildning för begåvade elever
                      </h4>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a-lararpaket---tryckt-bok--digital-lararlicens-36-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>
                            Lärarhandledning. Matematisk problemlösning 1-3.
                          </span>{" "}
                          Lund: Studentlitteratur.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a1---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel A1. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a2-elevpaket---tryckt-bok---digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel A2. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a3-elevpaket---tryckt-bok--digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel A3. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b-lararpaket---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel B Lärarpaket.</span> Lund:
                          Studentlitteratur.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b1---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel B1. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b2---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel B2. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b3---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel B3. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c-lararpaket---tryckt-bok--digital-lararlicens-36-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>
                            Lärarhandledning. Matematisk problemlösning 7-9.
                          </span>{" "}
                          Lund: Studentlitteratur.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c1---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel C1. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c2-elevpaket---tryckt-bok---digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel C2. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c3-elevpaket---tryckt-bok---digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel C3. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <h3 className="primary_h3">Publications</h3>
                      <h4 className="primary_h4">Publikationer</h4>
                      <div className="pubDiva">
                        <a
                          className="Bbutton"
                          href="http://sh.diva-portal.org/smash/resultList.jsf?aq2=%5B%5B%5D%5D&af=%5B%5D&searchType=RESEARCH&sortOrder2=title_sort_asc&query=&language=sv&aq=%5B%5B%7B%22personId%22%3A%22*shknna09*%22%7D%5D%5D&sf=all&aqe=%5B%5D&sortOrder=dateIssued_sort_desc&onlyFullText=false&noOfRows=50&dswid=-9221"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link to DiVa
                        </a>
                      </div>
                    </div>
                  </div>
                </Plx>
              )}
              {matches.large && (
                <Plx className="public" parallaxData={pubData}>
                  <div className="publiccenter">
                    <h1 className="primary_h1">Books</h1>
                    <h2 className="primary_h2">Böcker</h2>

                    <h3 className="primary_h3">Books for higher education</h3>
                    <h4 className="primary_h4">Böcker för högre utbildning</h4>

                    <div className="pubText">
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.bokus.com/bok/9789144134437/vad-ska-eleverna-lara-sig-och-vad-lar-de-sig-vanliga-missforstand-i-matematikundervisningen/"
                        >
                          Karlsson, N. & Kilborn, W. (2020).{" "}
                          <span>
                            Vad ska eleverna lära sig och vad lär de sig?
                            Vanliga missförstånd i matematikundervisningen.
                          </span>{" "}
                          Lund: Studentlitteratur.{" "}
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.diva-portal.org/smash/get/diva2:1266705/FULLTEXT01.pdf"
                        >
                          Karlsson, N. & Kilborn, W. (2018).{" "}
                          <span>
                            Det räcker om de förstår den. En studie av lärares
                            och elevers uppfattningar om multiplikation och
                            multiplikationstabellen.
                          </span>{" "}
                          Södertörn Studies in Higher Education.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://ncm.gu.se/wp-content/uploads/2018/03/skriftserie-nr2-karlsson_kilborn.pdf"
                        >
                          Karlsson, N. & Kilborn, W. (2017).{" "}
                          <span>
                            Formativ bedömning och didaktiskt stöd i matematik
                            för lärarstudenter: Diagnoser med didaktisk
                            uppföljning.
                          </span>{" "}
                          Göteborg: Göteborgs Universitet, NCM.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/kurslitteratur/lararutbildning-och-pedagogik/amnesdidaktik-f-6/konkretisering-och-undervisning-i-matematik"
                        >
                          Karlsson, N. & Kilborn, W. (2015).{" "}
                          <span>
                            Konkretisering och undervisning i matematik.
                            Matematikdidaktik för lärare.
                          </span>{" "}
                          Lund: Studentlitteratur.{" "}
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.gleerups.se/universitet-och-hogskola/universitet-och-hogskola-lararutbildning-pedagogik/lararutbildning-och-pedagogik-didaktik-och-pedagogik/matematikdidaktik-i-praktiken-att-undervisa-i-arskurs-1-6-p40688743"
                        >
                          Karlsson, N. & Kilborn, W. (2015).{" "}
                          <span>Matematikdidaktik i praktiken.</span> Malmö:
                          Gleerups Utbildning AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.gleerups.se/universitet-och-hogskola/universitet-och-hogskola-lararutbildning-pedagogik/lararutbildning-och-pedagogik-matematik/problemlosning-och-matematisk-modellering-p40692078"
                        >
                          Karlsson, N. & Kilborn, W. (2015).{" "}
                          <span>
                            Problemlösning och matematisk modellering.
                          </span>{" "}
                          Malmö: Gleerups Utbildning AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.bokus.com/bok/9789144090191/grundlaggande-algebra-funktioner-sannolikhetslara-och-statistik-matematikdidaktik-for-larare/"
                        >
                          Karlsson, N. & Kilborn, W. (2014).{" "}
                          <span>
                            Grundläggande algebra, funktioner, sannolikhetslära
                            och statistik. Matematikdidaktik för lärare.
                          </span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <h3 className="primary_h3">Books for gifted education</h3>
                      <h4 className="primary_h4">
                        Böcker för utbildning för begåvade elever
                      </h4>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a-lararpaket---tryckt-bok--digital-lararlicens-36-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>
                            Lärarhandledning. Matematisk problemlösning 1-3.
                          </span>{" "}
                          Lund: Studentlitteratur.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a1---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel A1. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a2-elevpaket---tryckt-bok---digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel A2. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-a3-elevpaket---tryckt-bok--digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel A3. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b-lararpaket---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel B Lärarpaket.</span> Lund:
                          Studentlitteratur.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b1---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel B1. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b2---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel B2. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/f-6/matematik/problemlosning/variabel-b3---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2021).{" "}
                          <span>Variabel B3. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c-lararpaket---tryckt-bok--digital-lararlicens-36-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>
                            Lärarhandledning. Matematisk problemlösning 7-9.
                          </span>{" "}
                          Lund: Studentlitteratur.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c1---digitalt--tryckt/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel C1. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c2-elevpaket---tryckt-bok---digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel C2. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.studentlitteratur.se/laromedel/6-9/matematik/problemlosning/variabel-c3-elevpaket---tryckt-bok---digital-elevlicens-12-man/"
                        >
                          Karlsson, N. & Kilborn, W. (2022).{" "}
                          <span>Variabel C3. Matematisk problemlösning.</span>{" "}
                          Lund: Studentlitteratur AB.
                        </a>
                      </p>
                      <h3 className="primary_h3">Publications</h3>
                      <h4 className="primary_h4">Publikationer</h4>
                      <div className="pubDiva">
                        <a
                          className="Bbutton"
                          href="http://sh.diva-portal.org/smash/resultList.jsf?aq2=%5B%5B%5D%5D&af=%5B%5D&searchType=RESEARCH&sortOrder2=title_sort_asc&query=&language=sv&aq=%5B%5B%7B%22personId%22%3A%22*shknna09*%22%7D%5D%5D&sf=all&aqe=%5B%5D&sortOrder=dateIssued_sort_desc&onlyFullText=false&noOfRows=50&dswid=-9221"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link to DiVa
                        </a>
                      </div>
                    </div>
                  </div>
                </Plx>
              )}
            </Fragment>
          )}
        </Media>
      </>
    );
  }
}
export default Books;
