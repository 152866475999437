import React, { useState } from "react";

const FiguresMain = ({ figures, id, start }) => {
  const [, setRe] = useState(false);

  const onLoad = () => {
    setRe(true);
  };

  const selectStart = () => {
    if (start === 0) {
      return (
        <>
          <div className="figures_h2 start">
            <p className="figures_h2__p">
              På den här webbsidan kan du följa hur en enkel parallellogram
              transformeras till rektangel, kvadrat och romb samt hur detta
              påverkar dess egenskaper. På motsvarande sätt kan du följa hur en
              godtycklig triangel transformeras. Här kan du även finnas
              kongruens och symmetriegenskaper för parallellogrammer och
              trianglar.
            </p>
            <p className="figures_h2__p figures_h2__p__luck">
              Lycka till <sub className="figures_h2__p__luck__triangel">△</sub>
            </p>
          </div>
        </>
      );
    }
  };

  const figure = figures.map((figure, index) => {
    return (
      <React.Fragment key={index}>
        <p className="figures_h2__p">{figure.name} </p>
        <div
          className="figure"
          onLoad={() => {
            onLoad();
          }}
        >
          {figure.svg}
        </div>
      </React.Fragment>
    );
  });

  return (
    <main className="main" role="main">
      <div className="container">
        {figure[id]}
        {selectStart()}
      </div>
    </main>
  );
};

export default FiguresMain;
