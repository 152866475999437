import React from "react";

const Footer = () => {
  return (
    <footer className="ettan_footer">
      <p className="ettan_footer_p">
        <span>
          <a
            className="ettan_footer_a"
            href="mailto:dmitry.marklund@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dmitry Marklund
          </a>{" "}
          &copy; 2022
        </span>
      </p>
    </footer>
  );
};

export default Footer;
