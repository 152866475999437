import React from "react";

import { SinCosA } from "../../particles/particles";

const SinCosAlpha = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="800"
        height="370"
        viewBox="-45 -5 305 320"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>Sin och Cos Alpha</title>
        <desc>Sin och Cos Alpha</desc>
        <SinCosA />
      </svg>
    </>
  );
};

export default SinCosAlpha;
