import React from "react";
import uuid from "react-uuid";
import Latex from "react-latex-next";
import { Link } from "react-router-dom";

const Aside = ({ funcs, setTitle, setGraphic, index, mainToggle }) => {
  return (
    <>
      <aside className="ettan_aside">
        <nav className="ettan_nav" role="navigation">
          <Menu
            funcs={funcs}
            setTitle={setTitle}
            setGraphic={setGraphic}
            index={index}
            mainToggle={mainToggle}
          />
        </nav>
      </aside>
    </>
  );
};

export const Menu = ({ funcs, setTitle, setGraphic, index, mainToggle }) => {
  return (
    <>
      {funcs.map((func, i) => {
        return (
          <div key={uuid()}>
            <Link
              to={func.to}
              key={i}
              className={`ettan_listItem ${index === i ? "ettan_active" : ""}`}
              onClick={() => {
                mainToggle(i);
                setTitle(func.menu);
                setGraphic(func.svg);
              }}
            >
              <Latex>{func.title}</Latex>{" "}
            </Link>
          </div>
        );
      })}
    </>
  );
};

export default Aside;
