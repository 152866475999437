import React, { Fragment } from "react";
import { Element, Link } from "react-scroll";
import Sensor from "react-visibility-sensor";
import Media from "react-media";

import { Helmet } from "react-helmet";

import { ReactComponent as Menu } from "../../assets/main/menu.svg";
import { ReactComponent as Close } from "../../assets/main/close.svg";

import Home from "./components/home/Home";
import AboutMe from "./components/information/AboutMe";
import Research from "./components/research/Research";
import Books from "./components/books/Books";
import Learning from "./components/e-learning/Learning";
import Contact from "./components/contact/Contact";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHome: false,
      isAbout: false,
      isBooks: false,
      isSelect: false,
      isLearning: false,
      isContact: false,
      show: false,
    };
  }

  toggle = () =>
    this.setState((currentState) => ({ show: !currentState.show }));

  render() {
    return (
      <div className="App">
        <Helmet>
          <meta
            name="description"
            content="Website contains information about her scientific activities, books, publications and contact information."
          />
          <meta property="og:title" content="Natalia Karlsson" />
          <meta property="og:site_name" content="Natalia Karlsson" />
          <meta property="og:image" content="./assets/photo_1_res.jpg" />
          <meta name="robots" content="index,follow" />

          <meta
            name="keywords"
            content="Natalia Karlsson, Natalia Lebedeva Karlsson, Wiggo Kilborn, Anatoly Alexandrovich Burenin, mathematician, higher education mathematics, mathematics education, applied mathematics, mathematical method, structures in mathematics education, mathematical models in mechanics and economics, applied mathematics,  books on mathematics, education for higher education, tillämpad matematik, docent i matematik, matematik, högskolelärare i matematik, matematikdidaktik"
          />
          <title>Natalia Karlsson Webpage</title>
        </Helmet>

        <nav>
          <Media
            queries={{
              small: "(max-width: 750px)",
              medium: "(min-width: 751px) and (max-width: 1281px)",
              large: "(min-width: 1282px)",
            }}
          >
            {(matches) => (
              <Fragment>
                {matches.small && (
                  <div
                    className="navPhone"
                    role="navigation"
                    aria-label="on the phone"
                  >
                    <div className="openM">
                      <Menu onClick={this.toggle}>
                        toggle: {this.state.show ? "show" : "hide"}{" "}
                      </Menu>
                      {this.state.show && (
                        <div className="navOverlay">
                          <Close onClick={this.toggle} />
                          <div className="navOverlayList">
                            <ul>
                              <li>
                                <Link
                                  to="home"
                                  spy={true}
                                  smooth={true}
                                  duration={500}
                                >
                                  <div
                                    className="itemO"
                                    style={{
                                      color: "#ffffff",
                                    }}
                                  >
                                    <span>Home</span>
                                  </div>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="ommig"
                                  spy={true}
                                  smooth={true}
                                  duration={500}
                                >
                                  <div
                                    className="itemO"
                                    style={{
                                      color: "#ffffff",
                                    }}
                                  >
                                    <span>Information</span>
                                  </div>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="lit"
                                  spy={true}
                                  smooth={true}
                                  duration={400}
                                >
                                  <div
                                    className="itemO"
                                    style={{
                                      color: "#ffffff",
                                    }}
                                  >
                                    <span>Research</span>
                                  </div>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="pub"
                                  spy={true}
                                  smooth={true}
                                  duration={500}
                                >
                                  <div
                                    className="itemO"
                                    style={{
                                      color: "#ffffff",
                                    }}
                                  >
                                    <span>Books</span>
                                  </div>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="learning"
                                  spy={true}
                                  smooth={true}
                                  offset={-20}
                                  duration={500}
                                  isDynamic={true}
                                >
                                  <div
                                    className="itemO"
                                    style={{
                                      color: "#ffffff",
                                    }}
                                  >
                                    <span>E-learning tools</span>
                                  </div>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="kontakt"
                                  spy={true}
                                  smooth={true}
                                  duration={500}
                                  isDynamic={true}
                                >
                                  <div
                                    className="itemO"
                                    style={{
                                      color: "#ffffff",
                                    }}
                                  >
                                    <span>Contact</span>
                                  </div>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>{" "}
                    {/* End openM */}
                    <div className="titleM">
                      <p>Natalia Karlsson</p>
                    </div>
                  </div>
                )}
                {matches.medium && (
                  <div
                    className="navTablet"
                    role="navigation"
                    aria-label="on the tablet"
                  >
                    <ul className="menu">
                      <li>
                        <Link
                          to="home"
                          spy={true}
                          smooth={true}
                          offset={-40}
                          duration={500}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isHome ? "#df2935" : "#ffffff",
                            }}
                          >
                            <span>Home</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="ommig"
                          spy={true}
                          smooth={true}
                          offset={-500}
                          duration={500}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isAbout ? "#df2935" : "#ffffff",
                            }}
                          >
                            <span>Information</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="lit"
                          spy={true}
                          smooth={true}
                          offset={-620}
                          duration={500}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isBooks ? "#df2935" : "#ffffff",
                            }}
                          >
                            <span>Research</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="pub"
                          spy={true}
                          smooth={true}
                          offset={-550}
                          duration={500}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isSelect
                                ? "#df2935"
                                : "#ffffff",
                            }}
                          >
                            <span>Books</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="learning"
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                          isDynamic={true}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isLearning
                                ? "#df2935"
                                : "#ffffff",
                            }}
                          >
                            <span>E-learning</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="kontakt"
                          spy={true}
                          smooth={true}
                          offset={0}
                          duration={500}
                          isDynamic={true}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isContact
                                ? "#df2935"
                                : "#ffffff",
                            }}
                          >
                            <span>Contact</span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
                {matches.large && (
                  <div
                    className="navHD"
                    role="navigation"
                    aria-label="on the widescreen"
                  >
                    <ul className="menu">
                      <li>
                        <Link
                          to="home"
                          spy={true}
                          smooth={true}
                          offset={0}
                          duration={500}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isHome ? "#df2935" : "#ffffff",
                            }}
                          >
                            <span>Home</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="ommig"
                          spy={true}
                          smooth={true}
                          offset={-480}
                          duration={500}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isAbout ? "#df2935" : "#ffffff",
                            }}
                          >
                            <span>Information</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="lit"
                          spy={true}
                          smooth={true}
                          offset={-610}
                          duration={500}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isBooks ? "#df2935" : "#ffffff",
                            }}
                          >
                            <span>Research</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="pub"
                          spy={true}
                          smooth={true}
                          offset={-560}
                          duration={500}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isSelect
                                ? "#df2935"
                                : "#ffffff",
                            }}
                          >
                            <span>Books</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="learning"
                          spy={true}
                          smooth={true}
                          offset={-200}
                          duration={400}
                          isDynamic={true}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isLearning
                                ? "#df2935"
                                : "#ffffff",
                            }}
                          >
                            <span>E-learning</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="kontakt"
                          spy={true}
                          smooth={true}
                          offset={0}
                          duration={500}
                          isDynamic={true}
                        >
                          <div
                            className="item"
                            style={{
                              color: this.state.isContact
                                ? "#df2935"
                                : "#ffffff",
                            }}
                          >
                            <span>Contact</span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </Fragment>
            )}
          </Media>
        </nav>

        <div>
          <Sensor
            onChange={(isVisible) => {
              this.setState({ isHome: isVisible });
            }}
          >
            <div className="hem-trigger" />
          </Sensor>
          <Element name="home">
            <Home />
          </Element>

          <Sensor
            onChange={(isVisible) => {
              this.setState({ isAbout: isVisible });
            }}
          >
            <div className="mig-trigger">&nbsp;</div>
          </Sensor>

          <Element name="ommig">
            <AboutMe />
          </Element>

          <Sensor
            onChange={(isVisible) => {
              this.setState({ isBooks: isVisible });
            }}
          >
            <div className="lit-trigger">&nbsp;</div>
          </Sensor>

          <Element name="lit">
            <Research />
          </Element>

          <Sensor
            onChange={(isVisible) => {
              this.setState({ isSelect: isVisible });
            }}
          >
            <div className="pub-trigger">&nbsp;</div>
          </Sensor>

          <Element name="pub">
            <Books />
          </Element>

          <Sensor
            onChange={(isVisible) => {
              this.setState({ isLearning: isVisible });
            }}
          >
            <div className="learning-trigger">&nbsp;</div>
          </Sensor>

          <Element name="learning">
            <Learning />
          </Element>

          <div className="kont-trigger" />

          <Sensor
            onChange={(isVisible) => {
              this.setState({ isContact: isVisible, isSelect: false });
            }}
          >
            <Element name="kontakt">
              <Contact />
            </Element>
          </Sensor>

          <div className="end" />
        </div>
      </div>
    );
  }
}

export default Main;
