import React from "react";

import { LanguageSelect } from "../particles/LanguageSelect";

const information = [
  {
    language: "in English",
    maintext: (
      <>
        <p className="resText__p">
          My research covers areas like applied mathematics and mathematics
          education.
        </p>
        <p className="resText__p">
          The research on applied mathematics deals with mathematic modeling of
          processes concerning non-linear dynamic theories for elasticity. That
          is to construct mathematical models and to choose mathematical
          methods, analytical as well as numerical, suitable to solve non-linear
          systems of partial differential equations and integral equations. This
          was also the essence of my thesis tutored by professor{" "}
          <a
            className="resText__a"
            href="http://www.mathnet.ru/php/person.phtml?personid=53145&option_lang=eng"
            target="_blank"
            rel="noopener noreferrer"
          >
            Anatoly Alexandrovich Burenin.
          </a>
        </p>
        <p className="resText__p">
          The research on applied mathematics with focus on mathematical models
          in economy deals fuzzy methods and their applications in order to
          formalize a strategy map. The method used in order to find functional
          relations between the elements in a strategy map is based on Mamdani's
          fuzzy inference method.
        </p>
        <p className="resText__p">
          The research on mathematics education deals with how mathematical
          concepts, structures, models and methods can be transformed and
          modified in mathematics teaching and how ”preliminary” mathematical
          concepts, taught in school, can be given such a quality that they
          without intellectual conflicts can be developed into correct
          mathematical concepts. The purpose is to offer every student a
          developable mathematics content adjusted to his or her own level. This
          is carried out in a co-operation between the two research areas,
          mathematics and mathematics education. Results from this type of
          research are presented in articles and reports, at international
          conferences as well as in a number of books with{" "}
          <a
            className="resText__a"
            href="https://www.studentlitteratur.se/person/forfattare/wiggo-kilborn/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wiggo Kilborn
          </a>{" "}
          as co-author.
        </p>
      </>
    ),
  },
  {
    language: "på Svenska",
    maintext: (
      <>
        <p className="resText__p">
          Min forskning omfattar områden tillämpad matematik och
          matematikdidaktik.
        </p>
        <p className="resText__p">
          Forskningen om tillämpad matematik handlar om matematisk modellering
          av processer inom ramen för icke-linjär dynamisk teori om elasticitet.
          Det handlar också om att konstruera matematiska modeller och välja
          matematiska metoder, såväl analytiska och numeriska, för att bland
          annat lösa icke-linjära system av partiella differentialekvationer och
          integralekvationer. Det är också kärna i mitt avhandlingsarbete där
          min forskningshandledare var professor{" "}
          <a
            className="resText__a"
            href="http://www.mathnet.ru/php/person.phtml?personid=53145&option_lang=eng"
            target="_blank"
            rel="noopener noreferrer"
          >
            Anatoly Alexandrovich Burenin
          </a>
          .
        </p>{" "}
        <p className="resText__p">
          Forskningen om matematikens användning inom ekonomi handlar om fuzzy
          metoder och dess användning för att formalisera en strategikarta. Den
          föreslagna metoden för att hitta funktionella förhållanden mellan
          elementen i en strategisk karta baseras på Mamdani's fuzzy
          inferensmetod.
        </p>{" "}
        <p className="resText__p">
          När det gäller matematikdidaktik handlar forskningen om hur
          matematiska begrepp, strukturer, modeller och metoder kan
          transformeras och modifieras i matematikundervisningen samt hur de
          ”preliminära” matematiska begrepp som undervisas i skolans
          matematikundervisning kan ges en sådan kvalitet att de utan konflikter
          kan utvecklas till korrekta matematiska begrepp. Syftet med detta är
          att kunna erbjuda varje elev möjligheter att tillägna sig ämnet
          matematik på sin nivå och samtidigt garantera att dessa kunskaper är
          utvecklingsbara. Dessa modeller utarbetas utgående från såväl
          teoretiska som empiriska studier och är kopplade till de två
          forskningsområdena matematik och matematikämnets didaktik.
        </p>{" "}
      </>
    ),
  },
];

class Research extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btnSelected: 0,
    };
  }

  onSelect = (i) => {
    this.setState({
      btnSelected: i,
      oldSelected: null,
    });
  };

  render() {
    const Index = this.state.btnSelected;
    const Txt = information[Index].maintext;

    return (
      <>
        <div className="blist">
          <LanguageSelect
            information={information}
            onSelect={this.onSelect}
            Index={Index}
            key={Index}
          />
        </div>
        <article className="resText">
          <div className="txt">{Txt}</div>
        </article>
      </>
    );
  }
}
export default Research;
