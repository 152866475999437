import React, { Fragment } from "react";
import Media from "react-media";

import Plx from "react-plx";

import AboutText from "./AboutText";

const textData = [
  {
    start: ".mig-trigger",
    duration: "100vh",
    properties: [
      {
        startValue: -20,
        endValue: -50,
        unit: "vh",
        property: "translateY",
      },
    ],
  },
];

class AboutMe extends React.Component {
  render() {
    return (
      <div className="om">
        <Media
          queries={{
            small: "(max-width: 750px)",
            medium: "(min-width: 751px) and (max-width: 1199px)",
            large: "(min-width: 1200px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.small && (
                <div className="presentation">
                  <AboutText />
                </div>
              )}
              {matches.medium && (
                <Plx className="presentation" parallaxData={textData}>
                  <AboutText />
                </Plx>
              )}
              {matches.large && (
                <Plx className="presentation" parallaxData={textData}>
                  <AboutText />
                </Plx>
              )}
            </Fragment>
          )}
        </Media>
      </div>
    );
  }
}
export default AboutMe;
