import React, { Fragment } from "react";
import Media from "react-media";
import Plx from "react-plx";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../../../assets/figurer/logo/Logo.svg";
import { ReactComponent as LogoFigures } from "../../../../assets/figurer/logo/Logo_Figures.svg";
import { ReactComponent as LogoEttan } from "../../../../assets/ettan/logo/logo.svg";
import { ReactComponent as MobileLogoEttan } from "../../../../assets/ettan/logo/logo_mobile.svg";

const learningData = [
  {
    start: ".learning-trigger",
    duration: "80vh",
    startOffset: "0",
    end: "self",
    endOffset: "40vh",
    easing: "easeInSine",
    properties: [
      {
        startValue: -80,
        endValue: 0,
        unit: "vh",
        property: "translateX",
      },
    ],
  },
];

class Learning extends React.Component {
  render() {
    return (
      <>
        <Media
          queries={{
            small: "(max-width: 750px)",
            medium: "(min-width: 751px) and (max-width: 1281px)",
            large: "(min-width: 1282px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.small && (
                <div className="learninglist">
                  <div className="learningcenter">
                    <div className="learningtop">
                      <h1 className="primary_h1">E-learning tools</h1>
                      <h2 className="primary_h2">Digitala läromedel</h2>
                    </div>

                    <div className="linfo">
                      <Link to="/figures">
                        <section>
                          <LogoFigures />
                          <Logo />
                        </section>
                      </Link>
                      <div className="mobile_line"></div>
                      <Link to="/ettan">
                        <section>
                          <MobileLogoEttan />
                        </section>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {matches.medium && (
                <Plx className="learninglist" parallaxData={learningData}>
                  <div className="learningcenter">
                    <div className="learningtop">
                      <h1 className="primary_h1">E-learning tools</h1>
                      <h2 className="primary_h2">Digitala läromedel</h2>
                    </div>

                    <div className="linfo">
                      <Link to="/figures">
                        <section>
                          <Logo />
                        </section>
                      </Link>
                      <div className="mobile_line"></div>
                      <Link to="/ettan">
                        <section>
                          <LogoEttan />
                        </section>
                      </Link>
                    </div>
                  </div>
                </Plx>
              )}
              {matches.large && (
                <Plx className="learninglist" parallaxData={learningData}>
                  <div className="learningcenter">
                    <div className="learningtop">
                      <h1 className="primary_h1">E-learning tools</h1>
                      <h2 className="primary_h2">Digitala läromedel</h2>
                    </div>

                    <div className="linfo">
                      <Link to="/figures">
                        <section>
                          <Logo />
                        </section>
                      </Link>
                      <div className="mobile_line"></div>
                      <Link to="/ettan">
                        <section>
                          <LogoEttan />
                        </section>
                      </Link>
                    </div>
                  </div>
                </Plx>
              )}
            </Fragment>
          )}
        </Media>
      </>
    );
  }
}
export default Learning;
