import React, { Fragment } from "react";
import Media from "react-media";

import Plx from "react-plx";

import ResearchInner from "./ResearchInner";

const litData = [
  {
    start: ".lit-trigger",
    duration: "100vh",
    properties: [
      {
        startValue: 0,
        endValue: -100,
        unit: "vh",
        property: "translateY",
      },
    ],
  },
];

class Research extends React.Component {
  render() {
    return (
      <>
        <Media
          queries={{
            small: "(max-width: 750px)",
            medium: "(min-width: 751px) and (max-width: 1199px)",
            large: "(min-width: 1200px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.small && (
                <div className="litlist">
                  {" "}
                  <div className="litcenter">
                    <h1 className="primary_h1">My research</h1>
                    <h2 className="primary_h2">Min forskning</h2>
                    <ResearchInner />
                  </div>
                </div>
              )}
              {matches.medium && (
                <div>
                  {" "}
                  <Plx className="litlist" parallaxData={litData}>
                    <div className="particle"></div>
                    <div className="particle"></div>
                    <div className="particle"></div>
                    <div className="particle"></div>
                    <div className="particle2"></div>
                    <div className="particle2"></div>
                    <div className="particle2"></div>
                    <div className="particle2"></div>
                    <div className="particle2"></div>

                    <div className="litcenter">
                      <h1 className="primary_h1">My research</h1>
                      <h2 className="primary_h2">Min forskning</h2>
                      <ResearchInner />
                    </div>
                  </Plx>
                </div>
              )}
              {matches.large && (
                <div>
                  {" "}
                  <Plx className="litlist" parallaxData={litData}>
                    <div className="particle"></div>
                    <div className="particle"></div>
                    <div className="particle"></div>
                    <div className="particle"></div>
                    <div className="particle2"></div>
                    <div className="particle2"></div>
                    <div className="particle2"></div>
                    <div className="particle2"></div>
                    <div className="particle2"></div>
                    <div className="litcenter">
                      <h1 className="primary_h1">My research</h1>
                      <h2 className="primary_h2">Min forskning</h2>
                      <ResearchInner />
                    </div>
                  </Plx>
                </div>
              )}
            </Fragment>
          )}
        </Media>
      </>
    );
  }
}
export default Research;
