import React from "react";
import { Routes, Route } from "react-router-dom";

import Main from "../components/main/Main";
import FiguresModule from "../components/figures/FiguresModule";
import EttanModule from "../components/ettan/EttanModule";
import Outlet from "./Outlet";

class App extends React.Component {
  render() {
    return (
      <>
        <Routes>
          <Route index element={<Main />} />
          <Route path="figures" element={<FiguresModule />} />

          <Route path="ettan" element={<EttanModule />}>
            <Route path="ettan" element={<Outlet />}>
              <Route path="vinkel" element={<Outlet />} />
              <Route path="koordinater" element={<Outlet />} />
              <Route path="adeg" element={<Outlet />} />
              <Route path="30deg" element={<Outlet />} />
              <Route path="45deg" element={<Outlet />} />
              <Route path="60deg" element={<Outlet />} />
            </Route>
          </Route>
        </Routes>
      </>
    );
  }
}

export default App;
